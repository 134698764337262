.contactContainerAll{
    height: 50vw;
    width: 100vw;
    background-color: #040016;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308002A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23070029'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    
}

.divLeft{
    margin-top: 8vw;
    height: 90%;
    width: 45%;
    display: flex;
    flex-direction: column;
}

.divLogoContact{
    height: 15vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 2vw;
}



.divLogoContact>div:nth-child(1){
    height: 100%;
    width: 15vw;
    
}

.divLogoContact>div:nth-child(1)>img{
    width: 100%;
    height: 100%;
}

.divLogoContact>div:nth-child(2){
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2vw;
}

.divLogoContact>div:nth-child(2)>h1{
    overflow: hidden;
    font-size: 3vw;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-weight: 500;
}
.divLogoContact>div:nth-child(2)>h2{
    overflow: hidden;
    font-size: 1.5vw;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-weight: 500;   
}

.divRedesContact{
    height: 25vw;
    display: flex;
    flex-direction: column;
}

.divRedesContact>h2{
    overflow: hidden;
    width: 100%;
    height: 15vw;
    margin-top: 3vw;
    margin-left: 2vw;
    font-size: 2.5vw;
}

.redesDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 15vw;
    width: 30vw;
}

.redesDiv>div{
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background-color: #ffffffd0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in .2s;
}

.redesDiv>div:hover{
    cursor: pointer;
    transform: scale(1.05);
}

.redesDiv>div:nth-child(1):hover{
    background-color: #0a66c3;
}

.redesDiv>div:nth-child(2):hover{
    background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.redesDiv>div:nth-child(3):hover{
    background: #000;
  }

  .redesDiv>div:nth-child(4):hover{
    background: #fe0032;
  }


.divRight{
    height: 90%;
    width: 45%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    align-items: center;
}
.divRight>h2{
    overflow: hidden;
    margin-top: 5vw;
}
.divRight>form{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 30vw;
}

.divRight>form>input{
    margin: 1vw;
    height: 3vw;
}

.divRight>form>textarea{
    margin: 1vw;
    height: 15vw;
}

.divRight>form>button{
    margin: 1vw;
    border: solid 2px #fff;
    border-radius: 50px;
    font-size: 1.3vw;
    background-color: transparent;
    color: #fff;
    height: 3vw;
    width: 10vw;
    margin-left: 30%;
    transition: all .3s;
}

.divRight>form>button:hover{
    background-color: #fff;
    color: black;
}

.redIcono{
    width: 5vw;
    height: 5vw;
    padding: .5vw;
    color: #040016;
    transition: all ease-in .2s;
}

.redIcono:hover{
    color: #fff;
}

@media screen and (max-width: 750px){
    .contactContainerAll{
        height: 100vh;
        flex-direction: column;
        justify-content: center;
    }
    
    .divLeft{
        margin-top: 8vh;
        height: 100%;
        width: 100%;
    }
    
    .divLogoContact{
        height: 25vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-left: 20vw;
    }
    
    
    
    .divLogoContact>div:nth-child(1){
        width: 25vw;
    }
    
    
    .divLogoContact>div:nth-child(2)>h1{
        font-size: 5vw;
    }
    .divLogoContact>div:nth-child(2)>h2{
        font-size: 3vw;  
    }
    
    .divRedesContact{
        height: 55vh;
    }
    
    .divRedesContact>h2{
        overflow: hidden;
        height: 80%;
        width: 47vw;
        margin-top: 5vh;
        margin-left: 2vw;
        font-size: 2.5vh;
        margin-right: 2vw;
    }
    
    .redesDiv{
        justify-content: center;
        height: 15vw;
        width: 100vw;
        position: absolute;
        z-index: 98;
       margin-top: 40vh;
    }
    
    .redesDiv>div{
        width: 10vw;
        height: 10vw;
        margin: 2vw;
    }
    
    
    .divRight{
        position: absolute;
        margin-left: 40vw;
        width: 40vw;
    }
    .divRight>h2{
        margin-top: 17vh;
        font-size: 2vh;
    }

    .divRight>form{
        width: 40vw;
    }
    
    .divRight>form>input{
        height: 6vw;
        font-size: 1vh;
    }
    
    .divRight>form>textarea{
        height: 10vh;
        font-size: 1vh;
    }
    
    .divRight>form>button{
        font-size: 3vw;
        height: 8vw;
        width: 20vw;
        margin-left: 25%;
        transition: all .3s;
    }
    
    
    .redIcono{
        width: 8vw;
        height: 8vw;
    }
    
}