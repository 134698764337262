.articuloDiv{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30vw;
    text-decoration: none;
    transition: all .2s ease-in-out;
}

.articuloDiv:hover{
    transform: scale(.95);
}
.articuloDiv>img{
    height: 17vw;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
}
.articuloDiv>h1{
    font-size: 1.5vw;
    width: 100%;
    overflow: hidden;
    text-align: center;
    margin-top: 1vw;
    color: #161853;
}

@media screen and (max-width: 750px){
    .articuloDiv{
        margin-top: 5vw;
        width: 70vw;
        height: 40vw;
    }
    .articuloDiv>img{
        height: 100%;
        width: 100%;
    }
    .articuloDiv>h1{
        display: none;
    }
}