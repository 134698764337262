
.aboutContainer{
    display: flex;
    flex-direction: row;
    height: 50vw;
    overflow-y:hidden;
   margin-top: 5vw;
   overflow: hidden;
}

.aboutContainer>img{
    width: 40vw;
    height: 40vw;
    object-fit: cover;
    margin-left: 5vw;
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
/* Para mejor soporte en navegadores antiguos puedes duplicar con -webkit- */
-webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%);
}

.aboutContainer>div{
    width: 50vw;
    overflow: hidden;
}

.aboutContainer>div>h1{
    font-size: 3vw;
    font-weight: 400;
    margin-left: 5vw;
    margin-top: 7vw;
    color: #161853; 
    overflow-y:hidden;
}

.aboutContainer>div>p{
    font-size: 1.3vw;
    width: 80%;
    line-height: 1.88;
    letter-spacing: -0.24px;
    margin-left: 5vw;
    margin-top: 1.5vw;
    color: #77777a;
}

.divButton{
    background-color: transparent;
    display: flex;
    border-radius: 25px;
    width: 15vw;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    color: #161853;
    font-size: 1.3vw;
    margin-left: 5vw;
    margin-top: 2vw;
    border: solid 2px #161853;
    transition: all .3s ease-in-out;
}

.divButton:hover{
    cursor: pointer;
    color: #fff;
    background-color: #161853;
    transform: scale(.97);
}

@media screen and (max-width: 750px){
    
.aboutContainer{
    height: auto;
   margin-top: 0vw;
}

.aboutContainer>img{
    margin-top: 7vh;
    margin-left: 62vw;
    width: 40vw;
    height: 40vw;
    position: absolute;
}

.aboutContainer>div{
    width: 65vw;
}

.aboutContainer>div>h1{
    font-size: 3vh;
    margin-top: 2vw;
    margin-left: 2vw;
    width: 50vw;
}

.aboutContainer>div>p{
    font-size: 2.4vw;
    width: 95%;
    margin-left: 2vw;
    margin-top: 1.5vw;
    color: #77777a;
    overflow: hidden;
}

.divButton{
    width: 25vw;
    height: 7vw;
    font-size: 3vw;
}
}