

.solapasContainer{
    width: 20vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding-top: 6vw;
    border-radius: 0;
    background-color: #040016;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308002A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23070029'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}


.solapasContainer>button{
    height: 3vw;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 1.3vw;
    border-bottom: solid 2px #fff;
    overflow: hidden;
    transition: all .2s ease-in-out;
    text-align: center;
}

.solapasContainer>button:hover{
transform: scale(1.2);
color: #fff;
}

.solapasContainer>button:first-child{
    border-top: solid 2px #fff;
}

.solapasNone{
    width: 20vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    padding-top: 6vw;
    border-radius: 0;
    background-color: #040016;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308002A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23070029'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}


.solapasNone>button{
    height: 3vw;
    color: #fff;
    background-color: transparent;
    border: none;
    font-size: 1.3vw;
    border-bottom: solid 2px #fff;
    overflow: hidden;
    transition: all .2s ease-in-out;
    text-align: center;
}

.solapasNone>button:hover{
transform: scale(1.2);
color: #fff;
}

.solapasNone>button:first-child{
    border-top: solid 2px #fff;
}




.solapaActiva{
    background-color: #fff !important;
    border: none !important;
    color: #181a53 !important; 
    transform: scale(1.2);  
}

.solapa{
    width: 70vw;
    margin-left: 25vw;
    overflow: scroll !important;
    animation: visib ease-in-out .5s forwards;
}

.invisible{
    width: 70vw;
    margin-left: 25vw;
    animation: invisible ease-in-out .5s forwards;
}

@keyframes invisible {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        display: none;
    }
}

@keyframes visib {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.flechaView{
    display: none;
}



@media screen and (max-width: 750px){
    .flechaView, .flechaNone{
        position:fixed;
        display: flex;
        z-index: 100;
        width: 10vw;
        height: 10vw;
        margin-top: 0;
        top: 50vh;
        background-color: #181a53;
        color: #fff;
        border-radius: 10px;
        margin-left: 55vw;
        animation: flechain ease-in-out .5s forwards;
    }
    .flechaView:hover{
        cursor: pointer;
    }

    .flechaNone{
        margin-left: -2vw;
        transform: rotate(180deg);
        animation: flechaout ease-in-out .5s forwards;
    }

    @keyframes flechain {
        0%{
            margin-left: 48vw;
        }
        100%{
            margin-left: -2vw;
        }
        
    }

    @keyframes flechaout {
        0%{
            margin-left: -2vw;
        }
        100%{
            margin-left: 49vw;
        }
        
    }

    .solapasContainer{
    width: 0vw;
    animation: aparece ease-in-out .5s forwards;
    z-index: 999999;
    }
    .solapasNone{
        width: 50vw;
        z-index: 999999;
        animation: desaparece ease-in-out .5s forwards;
    }
    @keyframes aparece {
        0%{
            width: 0vw;
        }
        100%{
            width: 50vw;
        }
    }

    @keyframes desaparece {
        0%{
            width: 50vw;
        }
        100%{
            width: 0vw;
        }
    }

    .solapasContainer>button{
        height: auto;
        font-size: 4vw;
    }
    .solapasContainer>button:first-child{
        margin-top: 50%
    }

    .solapa{
        width: 100vw;
        z-index: -1;
        margin-left: 5vw;
        overflow: scroll !important;
        animation: visib ease-in-out .5s forwards;
    }
    

.solapasNone>button{
    display: none;
}
}