.burgerContainer{
    display: none;
}



@media screen and (max-width: 750px) {
    .burgerContainer{
        display: flex;
    }
    .burgerButtonContainer{
        position: fixed;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        width: 10vw;
        height: 10vw;
        margin-top: 5vh;
        margin-left: 80vw;
        z-index: 100;
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);              
        transition: all .2s ease;
        overflow: hidden;
    }

    .burgerButtonContainer:hover{
        cursor: pointer;
        transform: scale(1.1);
    }

    .burgerButtonContainerClick{
        opacity: 0;
        position: fixed;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        width: 10vw;
        height: 10vw;
        margin-top: 80vh;
        margin-left: 80vw;
        z-index: 100;
        -webkit-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.75);              
        transition: all .2s ease;
    }

    .lineasButton{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 10vw;
        position: absolute;
        background-color: #fff;
    }

    .linea{
        width: 50%;
        height: .8vw;
        background-color: #000;
        margin: .8vw;
        border-radius: 10px;
        display: flex;
    }

    .navBarResponsive{
        width: 35vw;
        height: 100vh;
        background-color: #040016;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308002A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23070029'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
        z-index: 100;
        position: fixed;
        right: 0;
        transition: all .3s ease;
        animation: desaparece .3s ease-in forwards;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @keyframes desaparece {
        0%{
            width: 35vw;
            opacity: 1;
        }
        100%{
            width: 0vw;
            opacity: 0;
        }
        
    }

    .navBarResponsiveClick{
        width: 0vw;
        height: 100vh;
        background-color: #040016;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308002A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23070029'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
        z-index: 100;
        position: fixed;
        right: 0;
        transition: all .3s ease;
        animation: aparece .3s ease-in forwards;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .navBarResponsiveClick:hover{
        cursor: pointer;
    }

    .linkNavBarClick:nth-child(6){
        height: 8vw;
    }

    .linkNavBar:nth-child(6){
        height: 8vw;
    }

    .linkNavBar{
        text-align: center;
        margin-top: 5vh;
        font-size: 5vw;
        width: 35vw;
        animation: desaparece .3s ease-in forwards;
        text-decoration: none;
        color: #fff;
    }

    .linkNavBarClick{
        text-align: center;
        margin-top: 5vh;
        font-size: 5vw;
        width: 0vw;
        animation: aparece .3s ease-in forwards;
        text-decoration: none;
        color: #fff;
    }

    @keyframes aparece {
        0%{
            width: 0;
            opacity: 0;
        }
        100%{
            width: 35vw;
            opacity: 1;
        }
    }

    .nabBarBanderasBoton{
        box-shadow: inset 0 0 3px #77777a;
        width: 15vw;
        height: 7vw;
        display: flex;
        flex-direction: row;
        overflow: hidden;
        border-radius: 20px;
    }
    
    .navbarBanderas{
        width: 50%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .navbarBanderas>img{
        width: 70%;
        height: 65%;
        z-index: 100;
    }
    
    .navbarBanderaPintada{
        background-color: rgb(119, 119, 122, .5);
    }

}
