.servicesContainer{
    width: 100vw;
    height: 40vw;
    overflow: hidden;
}

.servicesContainer>h1{
    text-align: center;
    font-weight: 700;
    color: #fff;
    font-size: 3vw;
    letter-spacing: 2px;
    margin-top: 3.5vw;
    color: #161853;;
    font-weight: 400;
    overflow: hidden;
}
.divGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    width: 90%;
    margin-left: 5vw;
    margin-top: 2.5vw;
}

.divGrid>div{
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.divGrid>div>h1{
    font-size: 2vw;
    font-weight: 500;
    color: #161853;
    text-align: center;
    overflow-y:hidden;
}
.divGrid>div>p{
    line-height: 1.88;
    letter-spacing: -0.24px;
    font-size: 1.3vw;
    color: #77777a;
    font-weight: 400;
    margin-top: 1.25vw;
    text-align: justify;
    overflow: hidden;
}

.divButton{
    background-color: transparent;
    display: flex;
    border-radius: 25px;
    width: 15vw;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    color: #161853;
    font-size: 1.3vw;
    margin-left: 41vw;
    margin-top: 2.5vw;
    transition: all .3s ease-in-out;
    border: solid 2px #161853;
}

.divButton:hover{
    cursor: pointer;
    background-color: #161853;
    color: #fff;
    border: solid 2px #161853;
    transform: scale(.97);
    
}

@media screen and (max-width: 750px){
    .servicesContainer{
        width: 100vw;
        height: auto;
        margin-bottom: 3vh;
    }
    
    .servicesContainer>h1{
        font-size: 5vh;
        margin-top: 4vw;
    }
    .divGrid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .divGrid>div{
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    
    .divGrid>div>h1{
        font-size: 3vh;
        margin-top: 2vw;
    }
    .divGrid>div>p{
        letter-spacing: 0;
        font-size: 2vh;
        text-align: center;
    }
    
    .divButton{
        width: 30vw;
    height: 10vw;
    font-size: 3vw;
    margin-left: 35vw;
    }
    
       
}