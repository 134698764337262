*{
    margin: 0;
    overflow-x: hidden;
    font-family: "Exo";
    scroll-behavior: smooth;
    
/* Estilo del scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Ancho del scrollbar */
  background: transparent; /* Fondo transparente */
}

/* Estilo del botón o 'thumb' del scrollbar */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color del botón (thumb) con transparencia */
  border-radius: 50px; /* Hacer el botón redondo */
  border: 3px solid rgba(255, 255, 255, 0); /* Bordes transparentes para darle espacio */
}

/* Estilo al hacer hover sobre el botón del scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Cambia el color al pasar el mouse */
}

/* Estilo del track o área de desplazamiento */
::-webkit-scrollbar-track {
  background: transparent; /* Mantiene el fondo transparente */
}

}

*{
    margin: 0;
    scroll-behavior: smooth;
    font-family: 'Exo', sans-serif;
}




.page-transition {
    animation: transitionPagina 2s ease-in;
    width: 0vw;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 199;
    overflow: hidden;
  }


  @keyframes transitionPagina{
    0%{
        width: 100vw;
        opacity: 1;
    }
    80%{
      opacity: 1;
    }
    100%{
        width: 100vw;
        opacity: 0;
    }
  }


  .transiition-exit{
    animation: transitionExit 2s ease-in-out;
    width: 0vw;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 200;
    opacity: 0;
    overflow: hidden;
  }

  

  @keyframes transitionExit{
    0%{
        width: 100vw;
        opacity: 0;
    }
    10%{
      opacity: 1;
    }
    100%{
      width: 100vw;
        opacity: 1;
    }
  }


.loadLogo{
  animation: aplogo ease-in 1s forwards;
  opacity: 0;
}

@keyframes aplogo {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}