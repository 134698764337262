.clientsContainerAll{
    padding: 1vw 0 1vw 0;
    overflow: hidden;
}
.clientsContainerAll>h1{
    font-size: 2vw;
    text-align: center;
    overflow: hidden;
    margin: 1vw;
    color: #161853;
    width: 80vw;
    margin-left: 10vw;
}

.carouselDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.logoCliente{
    margin: 1vw;
    width: 10vw;
    height: 7vw;
    object-fit: contain;
    filter: grayscale(1);
    transition: all .3s;
}

.logoCliente:hover{
    filter: grayscale(0);
}

@media screen and (max-width: 750px){
    .clientsContainerAll{
        padding: 0;
        margin-top: 10vw;
        margin-bottom: 10vw;
    }
    .clientsContainerAll>h1{
        font-size: 2.7vw;    
    }
    
    .logoCliente{
        margin: 1vw;
        width: 10vw;
    }
}