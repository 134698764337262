.AudioItemContainer{
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    width: 25vw;
    overflow: hidden;
}

.AudioItemImg{
    width: 100%;
    height: 25vw;
    object-fit:cover;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    overflow: hidden;
}

.AudioItemAudio{
    width: 100%;
    height: 3vw;
    margin-top: .5vw;
    overflow: hidden;
}


.VideoItem {
    width: 100%; 
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease; /* Para evitar cambios abruptos */
  }


@media screen and (max-width: 750px){
    .AudioItemContainer{
        margin-top: 5vw;
        height: 40vw;
        width: 32vw;
    }
    
    .AudioItemImg{
        width: 100%;
        height: 100%;
    }
    
    .AudioItemAudio{
        width: 100%;
        height: 5vw;
        margin-top:1vw;
    } 
}
