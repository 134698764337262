.aboutAllContainer{
    background-color: #FFFFFF;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23D4D1FF' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23D6CDFF'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}

.aboutCover{
    padding-top: 7.5vw;
    width: 100vw;
    height: 40vw;
    background-color: #021C24;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23072638' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%230f2c4a' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%231a3158' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23273663' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23383E6C' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23404a76' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2347567f' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23506389' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23587091' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23617D9A' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
background-attachment: fixed;
background-size: cover;
background-attachment: fixed;
background-size: cover;
background-attachment: fixed;
display: flex;
flex-direction: row;
mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
/* Para mejor soporte en navegadores antiguos puedes duplicar con -webkit- */
-webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
}


.aboutCover>h1{
    font-family: "Days One", sans-serif;
    font-size: 4.5vw;
    width: 50vw;
    margin-left: 5vw;
    margin-top: 5vw;
    color: #fff;
    text-shadow: 0px 1px 2px rgba(24,26,83,0.6);
    overflow: hidden;
}

.aboutCover>p{
    font-family: "Days One", sans-serif;
    font-size: 2vw;
    text-align: end;
    width: 30vw;
    margin-top: 12.5vw;
    margin-left: 7vw;
    color: #fff;
    text-shadow: 0px 2px 3px rgba(119,119,122,0.6);
}

.imagenesCover{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    margin-top: -10vw;
}

.imagenesCover>img{
    width: 25vw;
    height: 12.5vw;
    object-fit: cover;
    margin-top: 2.5vw;
}

.imagenesCover>img:nth-child(2){
    margin-top: 0;
    width: 35vw;
    height: 17.5vw;
}


.tipsAbout{
    
    width: 100vw;
    height: 60vw;
    display: flex;
    flex-direction: row;
    color: #77777a;
}

.aboutVideo{
    width: 30vw;
    height: 40vw;
    object-fit: cover;
    background-color: #000;
}

.videoDiv{
    display: flex;
    flex-direction: row;
}
.videoDiv>img{
    width: 70vw;
}

.tipsAbout{
    padding-top: 3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    overflow: hidden;
      
}

.tipsAbout>h1{
    overflow: hidden; 
    font-size: 3vw;
    color: #161853;
    text-shadow: 0px 1px 2px rgba(24,26,83,0.6);
}
.tipsAbout>p{
    width: 80%;
    font-size: 1.3vw;
    text-align: justify;
    overflow: hidden;
    color: #77777a;
    margin-top: 2vw;
}

.imgAbout2{
    width: 100vw;
    height: 50vw;
    overflow: hidden;
    display: flex;
}

.imgAbout2>img{
    width: 60vw;
    height: 50vw;
    object-fit: cover;
}

.imgAbout2>video{
    width: 30vw;
    height: 30vw;
    object-fit: cover;
    margin-right: 2vw;
}

@media screen and (max-width: 750px){
    
    .aboutCover{
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    }
    
    
    .aboutCover>h1{
        font-size: 4vh;
    }
    
    .aboutCover>p{
        font-size: 2.5vh;
        text-align: start;
        width: 50vw;
    }
    
    .imagenesCover{
        width: 100vw;
        height: 60vh;
        flex-direction: column;
        justify-content: center;
        align-items: end;
        margin-top: -63vh;
    }
    
    .imagenesCover>img{
        width: 35vw;
        height: 17.5vw;
        margin-top: 0;
        margin: 2vw;
        border-radius: 10% 0 0 10%;
    }
    
    .imagenesCover>img:nth-child(2){
        margin-top: 1vw;
        width: 40vw;
        height: 20vw;
    }
    
    
    .aboutVideo{
        margin-bottom: 5vh;
    }

    .videoDiv>img{
        height: 92.5%;
    }
    
    
    
    .tipsAbout{
        padding-top: 0;
        align-items: center;
        justify-content: center;
        width: 90vw;
        height: 60vh;
        margin-left: 5vw;
    }
    
    .tipsAbout>h1{
        overflow: hidden; 
        font-size: 2.5vh;
        width: 100%;
        text-align: center;
    }
    .tipsAbout>p{
        font-size: 1.5vh;
        text-align: center;
    }
    
    
}