.podcastContainer{
    width: 100vw;
    height: 38vw;
    background-color: #040111;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%2308002A' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23070029'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.podcastContainer>img{
    margin-top: 2.5vw;
    width: 50vw;
    height: 30vw;
}

.podcastContainer>div{
    color: #fff;
    width: 40%;
    margin-left: 5vw;
    margin-top: 2vw;
    overflow: hidden;
}

.podcastContainer>div>p{
    font-size: 2vw;
}

.podcastContainer>div>h1{
    font-size: 5vw;
    margin-top: .5vw;
    width: 90%;
    font-family: "Days One", sans-serif;
    font-weight: 400;
    letter-spacing: .1vw;
    overflow-y:hidden;
}

.divButton{
    background-color: transparent;
    display: flex;
    border-radius: 25px;
    width: 10vw;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5vw;
    border: solid 2px #fff;
    margin-top: 1vw;
    transition: all .3s ease-in-out;
}

.divButton:hover{
    cursor: pointer;
    background-color: #fff;
    color: #000;
    transform: scale(.97);
}

@media screen and (max-width: 750px){
    .podcastContainer{
        width: 100vw;
        height: auto;
        display: flex;
        flex-direction: column;
        padding-bottom: 3vh;
    }
    
    .podcastContainer>img{
        display: none;
    }
    
    .podcastContainer>div{
        width: 100%;
    }
    
    .podcastContainer>div>p{
        font-size: 2.3vh;
    }
    
    .podcastContainer>div>h1{
        font-size: 5vh;
        width: 75%;
    }
    
    .divButton{
        width: 20vw;
        height: 7vw;
        font-size: 3vw;
        text-decoration: none;
    }
    
}