.contactPageDiv{
    overflow: hidden;
}

.IconArrowLeft{
    position: absolute;
    color: #fff;
    background-color: transparent;
    width: 5vw;
    height: 5vw;
    opacity: .5;
    transition: all .3s;
}

.IconArrowLeft:hover{
    opacity: 1;
}

@media screen and (max-width: 750px){
    .IconArrowLeft{
        display: none;
    }
}