.cover{
    width: 100%;
    height: 50vw;
    background-color: #1e1e1e;
    overflow-y: hidden;
}
.aboutVideo{
width: 100%;
height: 100%;
object-fit: cover;
}

@media screen and (max-width: 750px){
    .cover{
        width: 100%;
        height: 50vh;
        background-color: #1e1e1e;
        overflow-y: hidden;
    }
}

