.soundContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:4.5vw;
    height: 2.5vw;
    background-color: #fff;
    position: absolute;
    margin-left: 7.5vw;
    margin-top: -9.5vw;

}

.soundBarDiv{
    margin-left: 2%;
    width: 30%;
    height: 90%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.soundBarDiv>div{
    width: 3%;
    height: 95%;
    background-color: #272a88;
    animation: sound ease-in 1.7s infinite;
}

.soundBarDiv>div:nth-child(2){
    animation-delay: .2s;
}
.soundBarDiv>div:nth-child(3){
    animation-delay: .4s;
}
.soundBarDiv>div:nth-child(4){
    animation-delay: .6s;
}
.soundBarDiv>div:nth-child(5){
    animation-delay: .8s;
}
.soundBarDiv>div:nth-child(6){
    animation-delay: .7s;
}
.soundBarDiv>div:nth-child(7){
    animation-delay: .5s;
}
.soundBarDiv>div:nth-child(8){
    animation-delay: .3s;
}
.soundBarDiv>div:nth-child(10){
    animation-delay: .1s;
}

@keyframes sound {
    0%{
        height: 0;
    }
    50%{
        height: 95%;
    }
    100%{
        height: 0;
    }
}

.loadContainer{
    display: flex;
    flex-direction: row;
    width: 15vw;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 30vh;
    animation: aparecer ease-in-out 2s forwards;
}

.loadContainer>img{
    -webkit-box-shadow: 0px 29px 37px 0px rgba(24,26,83,1);
    -moz-box-shadow: 0px 29px 37px 0px rgba(24,26,83,1);
    box-shadow: 0px 29px 37px 0px rgba(24,26,83,1);
}
