.PodcastSectionContainer {
    display: grid;
    grid-template-columns: repeat(3, 23vw);
    margin-top: 7vw;
    }

    .PodcastSectionContainer>div{
        width: 20vw;
        height: 25vw;
        margin-top: 3vw;
    }
    @media screen and (max-width: 750px){
        .PodcastSectionContainer {
            display: grid;
            grid-template-columns: repeat(2, 50vw);
            }
        
            .PodcastSectionContainer>div{
                width: 40vw;
                height: auto;
                margin-top: 5vw;
            }
    }