.ourVoicesContainer{
    width: 100vw;
    height: 50vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.divVoice{
    width: 50vw;
    overflow: hidden;
}

.carDivCar{
    overflow: hidden;
}


.carouselDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}


.divVoice:first-child{
    margin-left: 10vw;
    width: 35vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.divVoice:first-child>h1{
    color: rgb(24, 26, 83);
    text-shadow: 0px 2px 3px rgba(24,26,83,0.6);
    font-size: 6vw;
    font-family: "Days One", sans-serif;
    font-weight: 400;
    margin-left: 2vw;
    overflow-y:hidden;
    margin-top: 12vw;
    overflow: hidden;
}

.divVoice:last-child{
    margin-right: 0vw;
    overflow: hidden;
}



.divButton{
    background-color: transparent;
    display: flex;
    border-radius: 25px;
    width: 10vw;
    height: 3.5vw;
    align-items: center;
    justify-content: center;
    color: #161853;
    font-size: 1.3vw;
    margin-top: 2.5vw;
    border: solid 2px #161853;
    margin-left: 2.5vw;
    transition: all .3s ease-in-out;
    z-index: 2;

}

.divButton:hover{
    cursor: pointer;
    background-color: #161853;
    color: #fff;
    transform: scale(.97);
}

.carouselContainer{
    width: 40vw;
    margin-left: 5vw;
    overflow: hidden;
}

.carouselContainer>div>div>div>div{
    margin-left: 2.5vw;
    overflow: hidden;
}


.carouselContainer>div>button{
    height: 30vw;
    margin-top: auto;
    overflow: hidden;
}

.carouselContainer>div>button>span{
    filter: invert(1);
    transition: all .3s ease-in-out;
}

.carouselContainer>div>button>span:hover{
    transform: scale(.95);
}

.dec{
    width: 50vw;
    height: 50vw;
    border-radius: 100px;
    position: absolute;
    margin-left: 8vw;
    z-index: 0.5;
    opacity: .3;
}

.decMic{
    height: 50vw;
    width: 50vw;
}

dec2d{
    display: flex;
}
.dec2{
    width: 5vw;
    height: 2vw;
    position: absolute;
    left: 43vw;
    margin-top: 2vw;
    z-index: 0;
    background-color: #000;
    transform: rotate(-25deg);
    opacity: 0;
    border-radius: 50px;
    animation: aparece .1s linear forwards;
}
@keyframes aparece {
    0%{
        opacity: 0;
    }
    100%{
        opacity: .2;
    }
}

.dec2:nth-child(2){
    margin-top: 10vw;
    left: 45vw;
    transform: rotate(-5deg);
}


.dec2:nth-child(4){
    left: 18vw;
    margin-top: 2vw;
    transform: rotate(25deg);
}

.dec2:nth-child(3){
    margin-top: 10vw;
    left: 16vw;
    transform: rotate(5deg);
}


.dec2dNone{
    display: none;
}




@media screen and (max-width: 750px){
    .ourVoicesContainer{
        height: 55vw;
    }
    
    
    
    .divVoice:first-child{
        margin-left: 2vw;
        width: 45vw;
    }
    .divVoice:first-child>h1{
        font-size: 10vw;
    }
    
   
    .divButton{
        width: 20vw;
        height: 8vw;
        font-size: 3vw;
    }
    
    
    .carouselContainer{
        width: 50vw;
        margin-left: 0vw;
        margin-top: 2vw;
    }
    
    .carouselContainer>div>div>div>div{
        margin-left: 0;        
    }
    
    
    .carouselContainer>div>button{
        height: 50vw;
        margin-top: 0;
    }
    
    .dec{
        margin-top: 3vw;
        margin-left: 0vw;
    }

    .dec2{
        display: none;
    }
}
