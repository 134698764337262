.audioBooksContainer{
    width: 100vw;
    margin-bottom: 10vh;
    overflow: hidden;
}

.audioBooksContainer>h1{
    font-weight: 500;
    font-size: 3vw;
    margin-top: 10vh;
    color: #161853;
    text-align: center;  
    margin-bottom: -12vh;
}

   .carouselDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 40vw;
}


.carouselContainer>div>button{
    height: 30vw;
    margin-top: auto;
    width: 5vw;
}
.carouselContainer>div>button>span{
    filter: invert(1);
    border-radius: 50%;
}

@media screen and (max-width: 750px){
    .audioBooksContainer{
        height: auto;
    }
    .carouselContainer>div>button>span{
       display: none;
    }
    .carouselDiv{
        margin-top: 0;
    }

    .audioBooksContainer>h1{
        font-weight: 500;
        font-size: 5vw;
        margin-top: 5vh;
        margin-bottom: 0vh;
    }
}
   

 