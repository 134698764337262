.navBarContainer{
    width: 100%;
    height: 6vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 99;
    background: #fff;
    overflow-y: hidden;
    animation: aparece .2s ease-in-out forwards;
    box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);

}

@keyframes aparece {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

.navBarContainerNone{
    width: 100%;
    height: 6vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 99;
    background: #fff;
    overflow-y: hidden;
    animation: desvanece .2s ease-in-out forwards;
    box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 6px 5px 0px rgba(0,0,0,0.75);
}
@keyframes desvanece {
    0%{
        opacity: 1;
    }    
    100%{
        opacity: 0;
    }
}

.logoAll{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}


.logoAll>span{
    color: #161853;
    font-size: 1.2vw;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-weight: 500;
}
.logoAll>span>i{
    font-style: normal;
    font-size: 1.5vw;
    font-family: "Atkinson Hyperlegible", sans-serif;
    font-weight: 500;
}
.logoImg{
    width: 5vw;
    margin: 3vw 1vw 3vw 1vw;
}

.navBarList{
    display: flex;
    flex-direction: row;
    list-style: none;
    overflow-y: hidden;
    margin-top: 1vw;
}

.navBarItem{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 .8vw 0;
    font-size: 1vw;
    transition: all .05s;
    transform-origin: center;
    margin-left: 4.8vw;
}
.navBarItem:hover{
    cursor: pointer;
    box-shadow: 4px 4px 4px rgba(119, 119, 122, 0.33)s
}

.navBarItem:nth-child(4):hover{
    transform: none !important;
}

.navBarItem>a{
    text-decoration: none;
    color: #77777a;
    font-size: 1.2vw;
    font-weight: 400;
}

.navBarItem:last-child>a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
}
.arrowUp{
    width: 3vw;
    height: 3vw;
    background: rgb(22, 24, 83);
    display: flex;
    position: fixed;
    z-index: 100;
    top: 90%;
    right: 3%;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.arrowUpNone{
    display: none;
}

.arrowUpIcon:hover{
    cursor: pointer;
    rotate: 360deg;
}

.arrowUpIcon{
    width: 2.5vw;
    height: 2.5vw;
    color: #fff;
    transition: all 1s;
}

.nabBarBanderasBoton{
    box-shadow: inset 0 0 3px #77777a;
    width: 5vw;
    height: 2.5vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 20px;
}

.navbarBanderas{
    width: 50%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbarBanderas>img{
    width: 70%;
    height: 65%;
    z-index: 100;
}

.navbarBanderaPintada{
    background-color: rgb(119, 119, 122, .5);
}


@media screen and (max-width: 750px) {
    .navBarContainer{
        display: none;
    }
    .navBarContainerNone{
        display: none;
    }
    .arrowUp{
        display: none;
    }
    .arrowUpNone{
        display: none;
    }
}